import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './home';
import AboutMe from './aboutme';
import ContactMe from './contactme';
import Projects from './projects';
import Resume from './resume';
import MedicationReminderApp from './medicationReminderApp';
import ClickItFixIt from './clickItFixIt';
import ProjectClassApp from './projectClassApp';
import UHReactApp from './uhReactApp'; 
import LaunchScreen from './launchScreen';
import LaunchScreenMed from './launchScreenMed';
import LoginScreen from './loginScreen';
import LoginScreenMed from './loginScreenMed';
import TouchID from './touchID';
import MainMenu from './mainMenu';
import JobEntry from './jobEntry';
import AdminLogin from './adminLogin';
import AdminWelcome from './adminWelcome';
import AdminTimeCard from './adminTimeCard';
import MainMenuRN from './mainMenuRN';
import LogActivityRN from './logActivityRN';
import InputOptionsRN from './inputOptionsRN';
import InputSelectionRN from './inputSelectionRN';
import LogFormRN from './logFormRN';
import UserInputRN from './userInputRN';
import TimeEntryRN from './timeEntryRN';
import ManualTimeEntryRN from './manualTimeEntryRN';
import CurrentTimeEntryRN from './currentTimeEntryRN';
import CreateAccountScreen from './createAccount';
import MedListScreen from './medListScreen';
import AddMedScreen from './addMedScreen';
import MedInfoScreen from './medInfoScreen';
import EditMedScreen from './editMedScreen';
import DeleteMed from './deleteMed';
import AllergyListScreen from './allergyListScreen';
import MDListScreen from './MDListScreen';
import AddMDScreen from './addMDScreen';
import MDInfoScreen from './MDInfoScreen';
import EditMDScreen from './editMDScreen';
import ProfileInfoScreen from './profileInfoScreen';
import InputValidation from './inputValidation';
import MedReminderNotification from './medReminderNotification';
import RefillReminderNotification from './refillReminderNotification';
import LandscapeExampleLogin from './landscapeExampleLogin';
import LandscapeExampleMedInfo from './landscapeExampleMedInfo';

const Routes = () => (
    <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/aboutme" component={AboutMe} />
        <Route path="/contactme" component={ContactMe} />
        <Route path="/projects" component={Projects} />
        <Route path="/resume" component={Resume} />
        <Route path="/medicationReminderApp" component={MedicationReminderApp} />
        <Route path="/clickItFixIt" component={ClickItFixIt} />
        <Route path="/projectClassApp" component={ProjectClassApp} />
        <Route path="/uhReactApp" component={UHReactApp} />
        <Route path="/launchScreen" component={LaunchScreen} />
        <Route path="/launchScreenMed" component={LaunchScreenMed} />
        <Route path="/loginScreen" component={LoginScreen} />
        <Route path="/loginScreenMed" component={LoginScreenMed} />
        <Route path="/createAccount" component={CreateAccountScreen} />
        <Route path="/touchID" component={TouchID} />
        <Route path="/mainMenu" component={MainMenu} />
        <Route path="/jobEntry" component={JobEntry} />
        <Route path="/adminLogin" component={AdminLogin} />
        <Route path="/adminWelcome" component={AdminWelcome} />
        <Route path="/adminTimeCard" component={AdminTimeCard} />
        <Route path="/mainMenuRN" component={MainMenuRN} />
        <Route path="/logActivityRN" component={LogActivityRN} />
        <Route path="/inputOptionsRN" component={InputOptionsRN} />
        <Route path="/inputSelectionRN" component={InputSelectionRN} />
        <Route path="/logFormRN" component={LogFormRN} />
        <Route path="/userInputRN" component={UserInputRN} />
        <Route path="/timeEntryRN" component={TimeEntryRN} />
        <Route path="/manualTimeEntryRN" component={ManualTimeEntryRN} />
        <Route path="/currentTimeEntryRN" component={CurrentTimeEntryRN} />
        <Route path="/medListScreen" component={MedListScreen} />
        <Route path="/addMedScreen" component={AddMedScreen} />
        <Route path="/medInfoScreen" component={MedInfoScreen} />
        <Route path="/editMedScreen" component={EditMedScreen} />
        <Route path="/deleteMed" component={DeleteMed} />
        <Route path="/allergyListScreen" component={AllergyListScreen} />
        <Route path="/MDListScreen" component={MDListScreen} />
        <Route path="/addMDScreen" component={AddMDScreen} />
        <Route path="/MDInfoScreen" component={MDInfoScreen} />
        <Route path="/editMDScreen" component={EditMDScreen} />
        <Route path="/profileInfoScreen" component={ProfileInfoScreen} />
        <Route path="/inputValidation" component={InputValidation} />
        <Route path="/medReminderNotification" component={MedReminderNotification} />
        <Route path="/refillReminderNotification" component={RefillReminderNotification} />
        <Route path="/landscapeExampleLogin" component={LandscapeExampleLogin} />
        <Route path="/landscapeExampleMedInfo" component={LandscapeExampleMedInfo} />
    </Switch>
)

export default Routes;