import React, { Component } from 'react';
import iosImg1 from '../images/MDListScreen.png';

class MDListScreen extends Component {
    render() {
        return (
            <div className= "launchScreen">
                <h1>Doctor List Screen</h1>
                <img src={iosImg1} alt="Doctor List Screen" height="600" width="425"/>
                <p style={{fontSize: '20px'}}>Users can view their doctor list on this screen.</p>
            </div>
        ) 
    } 
}

export default MDListScreen;