import React, { Component } from 'react';
import iosImg1 from '../images/landscapeExample1.png';

class LandscapeExampleLogin extends Component {
    render() {
        return (
            <div className= "launchScreen">
                <h1>Login Screen Landscape View</h1>
                <img src={iosImg1} alt="Login Screen Landscape View" height="425" width="600"/>
                <p style={{fontSize: '20px'}}>The application can also be viewed in the landscape orientation for convenience. </p>
            </div>
        ) 
    } 
}

export default LandscapeExampleLogin;