import React, { Component } from 'react';
import iosImg1 from '../images/addMedScreen.png';

class AddMedScreen extends Component {
    render() {
        return (
            <div className= "launchScreen">
                <h1>Add Medication Screen</h1>
                <img src={iosImg1} alt="Add Medication Screen" height="600" width="425"/>
                <p style={{fontSize: '20px'}}>Users can add a medication to their schedule on this screen.</p>
            </div>
        ) 
    } 
}

export default AddMedScreen;