import React, { Component } from 'react';
import iosImg1 from '../images/inputValidationExample.png';

class InputValidation extends Component {
    render() {
        return (
            <div className= "launchScreen">
                <h1>User Input Validation</h1>
                <img src={iosImg1} alt="User Input Validation" height="600" width="425"/>
                <p style={{fontSize: '20px'}}>User input is validated throughout the app to avoid entry of incorrect information. <br/>
                                              If the user input is incorrect, an alert appears as shown above and the input is not accepted. </p>
            </div>
        ) 
    } 
}

export default InputValidation;