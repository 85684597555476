import React, { Component } from 'react';
import iosImg1 from '../images/profileInfoScreen.png';

class ProfileInfoScreen extends Component {
    render() {
        return (
            <div className= "launchScreen">
                <h1>Profile Information Screen</h1>
                <img src={iosImg1} alt="Profile Information Screen" height="600" width="425"/>
                <p style={{fontSize: '20px'}}>Users can view the details for their profile on this screen. <br/>
                                              Users can also logout of the application on this screen.</p>
            </div>
        ) 
    } 
}

export default ProfileInfoScreen;