import React, { Component } from 'react';
import iosImg1 from '../images/addMDScreen.png';

class AddMDScreen extends Component {
    render() {
        return (
            <div className= "launchScreen">
                <h1>Add Doctor Screen</h1>
                <img src={iosImg1} alt="Add Doctor Screen" height="600" width="425"/>
                <p style={{fontSize: '20px'}}>Users can add a doctor to their list on this screen.</p>
            </div>
        ) 
    } 
}

export default AddMDScreen;