import React, { Component } from 'react';
import iosImg1 from '../images/refillReminderNotification.png';

class RefillReminderNotification extends Component {
    render() {
        return (
            <div className= "launchScreen">
                <h1>Refill Reminder Notification</h1>
                <img src={iosImg1} alt="Refill Reminder Notification" height="600" width="425"/>
                <p style={{fontSize: '20px'}}>Users are reminded to refill their medications as shown in the iOS notification above. </p>
            </div>
        ) 
    } 
}

export default RefillReminderNotification;