import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import iosImg1 from '../images/Medi-Ready.png';
import iosImg2 from '../images/loginScreen.png';
import iosImg3 from '../images/medListScreen.png';
import iosImg4 from '../images/addMedScreen.png';
import iosImg5 from '../images/createAccountScreen.png';
import iosImg6 from '../images/medInfoScreen.png';
import iosImg7 from '../images/editMedScreen.png';
import iosImg8 from '../images/allergyListScreen.png';
import iosImg9 from '../images/deleteMed.png';
import iosImg10 from '../images/MDListScreen.png';
import iosImg11 from '../images/addMDScreen.png';
import iosImg12 from '../images/MDInfoScreen.png';
import iosImg13 from '../images/editMDScreen.png';
import iosImg14 from '../images/profileInfoScreen.png';
import iosImg15 from '../images/inputValidationExample.png';
import iosImg16 from '../images/medReminderNotification.png';
import iosImg17 from '../images/refillReminderNotification.png';
import iosImg18 from '../images/landscapeExample1.png';
import iosImg19 from '../images/landscapeExample2.png';

class MedicationReminderApp extends Component {
    
    render() {

        const responsive = {
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 3,
              slidesToSlide: 3 // optional, default to 1.
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 2,
              slidesToSlide: 2 // optional, default to 1.
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            }
          };

        return (
            <div>
                <div className="ios-text">
                    <h1>iOS Class Project</h1>
                    <p style={{marginBottom: '10px'}}>Description:  This is an iOS mobile application I created for a University of Houston class project.  
                        The targeted user is any person who needs help managing his or her medications.  The user can
                        log their current medications, doctors and allergies.  The application will remind users when
                        it is time to take a medication or refill a medication through iOS push notifications.</p>

                    <p style={{marginTop: '10px'}}>In this class, I learned how to create iOS mobile applications that are 
                    user friendly.  Any user who understands how to use the standard iOS applications created by Apple,
                    such as the iOS Calendar app, will be able to quickly learn how to use this medication reminder app.  The 
                    design of the app follows the principles of those iOS applications so the app will feel familiar to iOS users.
                    Although not all screen shots are shown below, the add, edit, delete and view information screens are similar for
                    the medication, allergy, doctor and profile screens. </p>

                    <p>Frontend:  Swift and XCode</p>

                    <p style={{textAlign: 'center'}}>(Click on pictures below to enlarge)</p>
                </div>

               <Carousel
                swipeable={true}
                draggable={false}
                showDots={true}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={false}
                // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                // autoPlaySpeed={1000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                deviceType={this.props.deviceType}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                >
                    <div className= "launchScreen">
                            <Link to="/launchScreenMed">
                                <img src={iosImg1} alt="Launch Screen" height="300" width="200"/>
                            </Link>
                        <p>Launch Screen</p>
                        <p>This screen briefly appears when the application loads.</p>
                    </div>
                    
                    <div className="loginScreen">
                        <Link to="/loginScreenMed">
                            <img src={iosImg2} alt="Login Screen" height="300" width="200"/>
                        </Link> 
                        <p>Login Screen</p>
                        <p>Users can login on this screen.</p>
                    </div>

                    <div className="loginScreen">
                        <Link to="/createAccount">
                            <img src={iosImg5} alt="Create Account Screen" height="300" width="200"/>
                        </Link>
                        <p>Create Account Screen</p>
                        <p>Users can create an account on this screen.</p>
                    </div>
                    
                    <div className="loginScreen">
                        <Link to="/medListScreen">
                            <img src={iosImg3} alt="Medication Schedule Screen" height="300" width="200"/>
                        </Link>
                        <p>Medication Schedule Screen</p>
                        <p>Users can view their medication schedule on this screen.</p>
                    </div>

                    <div className="loginScreen">
                        <Link to="/deleteMed">
                            <img src={iosImg9} alt="Delete Medication" height="300" width="200"/>
                        </Link>
                        <p>Delete Medication</p>
                        <p>Users can delete each medication in the schedule as shown above.</p>
                    </div>
                    
                    <div className="loginScreen">
                        <Link to="/addMedScreen">
                            <img src={iosImg4} alt="Job Entry Screen" height="300" width="200"/>
                        </Link>
                        <p>Add Medication Screen</p>
                        <p>Users can add a medication to their schedule on this screen.</p>
                    </div>
                    
                    <div className="loginScreen">
                        <Link to="/medInfoScreen">
                            <img src={iosImg6} alt="Medication Information Screen" height="300" width="200"/>
                        </Link>
                        <p>Medication Information Screen</p>
                        <p>Users can view the details for each medication on this screen.</p>
                    </div>

                    <div className="loginScreen">
                        <Link to="/editMedScreen">
                            <img src={iosImg7} alt="Edit Medication Screen" height="300" width="200"/>    
                        </Link>
                        <p>Edit Medication Screen</p>
                        <p>Users can edit the information for each medication on this screen.</p>
                    </div>
                    
                    <div className="loginScreen">
                        <Link to="/allergyListScreen">
                            <img src={iosImg8} alt="Allergy List Screen" height="300" width="200"/>
                        </Link>
                        <p>Allergy List Screen</p>
                        <p>Users can view their allergy list on this screen.</p>
                    </div>

                    <div className="loginScreen">
                        <Link to="/MDListScreen">
                            <img src={iosImg10} alt="Doctor List Screen" height="300" width="200"/>
                        </Link>
                        <p>Doctor List Screen</p>
                        <p>Users can view their doctor list on this screen.</p>
                    </div>

                    <div className="loginScreen">
                        <Link to="/addMDScreen">
                            <img src={iosImg11} alt="Add Doctor Screen" height="300" width="200"/>
                        </Link>
                        <p>Add Doctor Screen</p>
                        <p>Users can add a doctor to their list on this screen.</p>
                    </div>

                    <div className="loginScreen">
                        <Link to="/MDInfoScreen">
                            <img src={iosImg12} alt="Doctor Information Screen" height="300" width="200"/>
                        </Link>
                        <p>Doctor Information Screen</p>
                        <p>Users can view the details for each doctor on this screen.</p>
                    </div>

                    <div className="loginScreen">
                        <Link to="/editMDScreen">
                            <img src={iosImg13} alt="Edit Doctor Screen" height="300" width="200"/>    
                        </Link>
                        <p>Edit Doctor Screen</p>
                        <p>Users can edit the information for each doctor on this screen.</p>
                    </div>

                    <div className="loginScreen">
                        <Link to="/profileInfoScreen">
                            <img src={iosImg14} alt="Profile Information Screen" height="300" width="200"/>
                        </Link>
                        <p>Profile Information Screen</p>
                        <p>Users can view the details for their profile on this screen.</p>
                    </div>

                    <div className="loginScreen">
                        <Link to="/inputValidation">
                            <img src={iosImg15} alt="User Input Validation" height="300" width="200"/>
                        </Link>
                        <p>User Input Validation</p>
                        <p>User input is validated to avoid the entry of incorrect information as shown in the alert above.</p>
                    </div>

                    <div className="loginScreen">
                        <Link to="/medReminderNotification">
                            <img src={iosImg16} alt="Medication Reminder Notification" height="300" width="200"/>
                        </Link>
                        <p>Medication Reminder Notification</p>
                        <p>Users are reminded to take their medications per the schedule as shown in the notification above.</p>
                    </div>

                    <div className="loginScreen">
                        <Link to="/refillReminderNotification">
                            <img src={iosImg17} alt="Refill Reminder Notification" height="300" width="200"/>
                        </Link>
                        <p>Refill Reminder Notification</p>
                        <p>Users are reminded to refill their medications as shown in the notification above.</p>
                    </div>

                    <div className="loginScreen">
                        <Link to="/landscapeExampleLogin">
                            <img src={iosImg18} alt="Login Screen Landscape View" height="200" width="300"/>
                        </Link>
                        <p>Login Screen Landscape View</p>
                        <p>The application can also be viewed in the landscape orientation for convenience.</p>
                    </div>

                    <div className="loginScreen">
                        <Link to="/landscapeExampleMedInfo">
                            <img src={iosImg19} alt="Medication Information Screen Landscape View" height="200" width="300"/>
                        </Link>
                        <p>Medication Information Screen Landscape View</p>
                        <p>The application can also be viewed in the landscape orientation for convenience.</p>
                    </div>

                </Carousel>;

            </div>
        )
    }
}

export default MedicationReminderApp;