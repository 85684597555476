import React, { Component } from 'react';
import iosImg1 from '../images/deleteMed.png';

class DeleteMed extends Component {
    render() {
        return (
            <div className= "launchScreen">
                <h1>Delete Medication</h1>
                <img src={iosImg1} alt="Delete Medication" height="600" width="425"/>
                <p style={{fontSize: '20px'}}>Users can delete each medication in the schedule as shown above. <br/> 
                                              Doctors and allergies are also deleted from their lists in this manner.</p>
            </div>
        ) 
    } 
}

export default DeleteMed;