import React, { Component } from 'react';
import iosImg1 from '../images/medListScreen.png';

class MedListScreen extends Component {
    render() {
        return (
            <div className= "launchScreen">
                <h1>Medication Schedule Screen</h1>
                <img src={iosImg1} alt="Medication Schedule Screen" height="600" width="425"/>
                <p style={{fontSize: '20px'}}>Users can view their medication schedule on this screen. <br/> 
                                              Users can navigate through the app using the tab bar at the bottom. </p>
            </div>
        ) 
    } 
}

export default MedListScreen;