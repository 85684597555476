import React, { Component } from 'react';
import iosImg1 from '../images/MDInfoScreen.png';

class MDInfoScreen extends Component {
    render() {
        return (
            <div className= "launchScreen">
                <h1>Doctor Information Screen</h1>
                <img src={iosImg1} alt="Doctor Information Screen" height="600" width="425"/>
                <p style={{fontSize: '20px'}}>Users can view the details for each doctor on this screen. <br/>
                                              The information is entered by the user. </p>
            </div>
        ) 
    } 
}

export default MDInfoScreen;