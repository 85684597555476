import React, { Component } from 'react';
import iosImg1 from '../images/medInfoScreen.png';

class MedInfoScreen extends Component {
    render() {
        return (
            <div className= "launchScreen">
                <h1>Medication Information Screen</h1>
                <img src={iosImg1} alt="Medication Information Screen" height="600" width="425"/>
                <p style={{fontSize: '20px'}}>Users can view the details for each medication on this screen. <br/>
                                              The information is entered by the user. </p>
            </div>
        ) 
    } 
}

export default MedInfoScreen;