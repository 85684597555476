import React, { Component } from 'react';
import iosImg1 from '../images/allergyListScreen.png';

class AllergyListScreen extends Component {
    render() {
        return (
            <div className= "launchScreen">
                <h1>Allergy List Screen</h1>
                <img src={iosImg1} alt="Allergy List Screen" height="600" width="425"/>
                <p style={{fontSize: '20px'}}>Users can view their allergy list on this screen. </p>
            </div>
        ) 
    } 
}

export default AllergyListScreen;